/**
* Template Name: Bikin - v4.7.0
* Template URL: https://bootstrapmade.com/bikin-free-simple-landing-page-template/
* Author: BootstrapMade.com
* License: https://bootstrapmade.com/license/
*/

/*--------------------------------------------------------------
# General
--------------------------------------------------------------*/

@import url('https://fonts.googleapis.com/css2?family=Kanit:wght@100;200;300;500;700&family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

:root {
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  /* --bs-theme-main: #1f8552; */
  --bs-theme-main: #44a476;
  --bs-primary: #770000;
  --bs-secondary: #555c64;
  --bs-success: #115e39;
  --bs-info: #0dcaf0;
  --bs-warning: #ffc107;
  --bs-danger: #dc3545;
  --bs-light: #f8f9fa;
  --bs-dark: #212529;
  --bs-primary-rgb: 60, 87, 164;
  --bs-secondary-rgb: 108, 117, 125;
  --bs-success-rgb: 25, 135, 84;
  --bs-info-rgb: 83, 208, 231;
  --bs-warning-rgb: 243, 204, 91;
  --bs-danger-rgb: 243, 92, 108;
  --bs-light-rgb: 248, 249, 250;
  --bs-dark-rgb: 33, 37, 41;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  /* --bs-nav-link-color: rgba(255,255,255, 0.7); */
  --bs-navbar-color: rgba(255, 255, 255, 0.75)
}

body {
    font-family: "Poppins", "kanit", "Open Sans", sans-serif;
    color: #444444;
  }
  
  a {
    color: #3b4ef8;
    text-decoration: none !important;
  }
  
  a:hover {
    color: #6c7afa;
    text-decoration: none;
  }
  
  h1, h2, h3, h4, h5, h6 {
    font-family: "Poppins", "kanit", sans-serif;
  }
  
  /*--------------------------------------------------------------
  # Back to top button
  --------------------------------------------------------------*/
  .back-to-top {
    position: fixed;
    visibility: hidden;
    opacity: 0;
    right: 15px;
    bottom: 15px;
    z-index: 996;
    background: #3b4ef8;
    width: 40px;
    height: 40px;
    border-radius: 4px;
    transition: all 0.4s;
  }
  .back-to-top i {
    font-size: 28px;
    color: #fff;
    line-height: 0;
  }
  .back-to-top:hover {
    background: #6272f9;
    color: #fff;
  }
  .back-to-top.active {
    visibility: visible;
    opacity: 1;
  }
  
  /*--------------------------------------------------------------
  # Preloader
  --------------------------------------------------------------*/
  #preloader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;
    overflow: hidden;
    background: #fff;
  }
  
  #preloader:before {
    content: "";
    position: fixed;
    top: calc(50% - 30px);
    left: calc(50% - 30px);
    border: 6px solid #3b4ef8;
    border-top-color: #e2e5fe;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    -webkit-animation: animate-preloader 1s linear infinite;
    animation: animate-preloader 1s linear infinite;
  }
  
  @-webkit-keyframes animate-preloader {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  @keyframes animate-preloader {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  /*--------------------------------------------------------------
  # Disable aos animation delay on mobile devices
  --------------------------------------------------------------*/
  @media screen and (max-width: 768px) {
    [data-aos-delay] {
      transition-delay: 0 !important;
    }
  }
  /*--------------------------------------------------------------
  # Header
  --------------------------------------------------------------*/
  #header {
    background: #f6f7ff;
    border-bottom: 2px solid #eceefe;
    transition: all 0.5s;
    z-index: 997;
    padding: 15px 0;
  }
  #header .logo {
    font-size: 30px;
    margin: 0;
    padding: 0;
    line-height: 1;
    font-weight: 600;
    letter-spacing: 1px;
    font-family: "Poppins", "kanit", sans-serif;
  }
  #header .logo a {
    color: #2d405f;
  }
  #header .logo img {
    max-height: 40px;
  }
  
  /*--------------------------------------------------------------
  # Navigation Menu
  --------------------------------------------------------------*/
  /**
  * Desktop Navigation 
  */
  .navbar {
    padding: 0;
  }
  .navbar ul {
    margin: 0;
    padding: 0;
    display: flex;
    list-style: none;
    align-items: center;
  }
  .navbar li {
    position: relative;
  }
  .navbar a, .navbar a:focus {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0 10px 30px;
    font-family: "Poppins", "kanit", sans-serif;
    font-size: 14px;
    font-weight: 600;
    color: #2d405f;
    white-space: nowrap;
    transition: 0.3s ease;
  }
  .navbar a i, .navbar a:focus i {
    font-size: 12px;
    line-height: 0;
    margin-left: 5px;
  }
  .navbar a:hover, .navbar .active, .navbar .active:focus, .navbar li:hover > a {
    color: #3b4ef8;
  }
  .navbar .getstarted, .navbar .getstarted:focus {
    background: #e2e5fe;
    padding: 9px 25px;
    margin-left: 30px;
    border-radius: 5px;
    font-weight: 600;
    color: #fff;
    color: #3b4ef8;
  }
  .navbar .getstarted:hover, .navbar .getstarted:focus:hover {
    color: #fff;
    background: #3b4ef8;
  }
  .navbar .dropdown ul {
    display: block;
    position: absolute;
    left: 14px;
    top: calc(100% + 30px);
    margin: 0;
    padding: 10px 0;
    z-index: 99;
    opacity: 0;
    visibility: hidden;
    background: #fff;
    box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
    transition: 0.3s;
    border-radius: 4px;
  }
  .navbar .dropdown ul li {
    min-width: 200px;
  }
  .navbar .dropdown ul a {
    padding: 10px 20px;
    text-transform: none;
    font-weight: 500;
  }
  .navbar .dropdown ul a i {
    font-size: 12px;
  }
  .navbar .dropdown ul a:hover, .navbar .dropdown ul .active:hover, .navbar .dropdown ul li:hover > a {
    color: #3b4ef8;
  }
  .navbar .dropdown:hover > ul {
    opacity: 1;
    top: 100%;
    visibility: visible;
  }
  .navbar .dropdown .dropdown ul {
    top: 0;
    left: calc(100% - 30px);
    visibility: hidden;
  }
  .navbar .dropdown .dropdown:hover > ul {
    opacity: 1;
    top: 0;
    left: 100%;
    visibility: visible;
  }
  @media (max-width: 1366px) {
    .navbar .dropdown .dropdown ul {
      left: -90%;
    }
    .navbar .dropdown .dropdown:hover > ul {
      left: -100%;
    }
  }
  
  /**
  * Mobile Navigation 
  */
  .mobile-nav-toggle {
    color: #2d405f;
    font-size: 28px;
    cursor: pointer;
    display: none;
    line-height: 0;
    transition: 0.5s;
  }
  .mobile-nav-toggle.bi-x {
    color: #fff;
  }
  
  @media (max-width: 991px) {
    .mobile-nav-toggle {
      display: block;
    }
  
    .navbar ul {
      display: none;
    }
  }
  .navbar-mobile {
    position: fixed;
    overflow: hidden;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: rgba(29, 41, 60, 0.9);
    transition: 0.3s;
    z-index: 999;
  }
  .navbar-mobile .mobile-nav-toggle {
    position: absolute;
    top: 15px;
    right: 15px;
  }
  .navbar-mobile ul {
    display: block;
    position: absolute;
    top: 55px;
    right: 15px;
    bottom: 15px;
    left: 15px;
    padding: 10px 0;
    border-radius: 6px;
    background-color: #fff;
    overflow-y: auto;
    transition: 0.3s;
  }
  .navbar-mobile a, .navbar-mobile a:focus {
    padding: 10px 20px;
    font-size: 15px;
    color: #2d405f;
  }
  .navbar-mobile a:hover, .navbar-mobile .active, .navbar-mobile li:hover > a {
    color: #3b4ef8;
  }
  .navbar-mobile .getstarted, .navbar-mobile .getstarted:focus {
    margin: 15px;
  }
  .navbar-mobile .dropdown ul {
    position: static;
    display: none;
    margin: 10px 20px;
    padding: 10px 0;
    z-index: 99;
    opacity: 1;
    visibility: visible;
    background: #fff;
    box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
  }
  .navbar-mobile .dropdown ul li {
    min-width: 200px;
  }
  .navbar-mobile .dropdown ul a {
    padding: 10px 20px;
  }
  .navbar-mobile .dropdown ul a i {
    font-size: 12px;
  }
  .navbar-mobile .dropdown ul a:hover, .navbar-mobile .dropdown ul .active:hover, .navbar-mobile .dropdown ul li:hover > a {
    color: #3b4ef8;
  }
  .navbar-mobile .dropdown > .dropdown-active {
    display: block;
  }
  
  /*--------------------------------------------------------------
  # Hero Section
  --------------------------------------------------------------*/
  #hero {
    width: 100%;
    height: 100vh;
    background: white;
    border-bottom: 2px solid white;
    text-align: center;
  }
  #hero .container {
    padding-top: 40px;
  }
  #hero h1 {
    margin: 0;
    font-size: 48px;
    font-weight: 700;
    line-height: 56px;
    color: #141d2b;
    width: 60%;
    color: #2d405f;
  }
  #hero h2 {
    color: #466393;
    margin: 15px 0 0 0;
    font-size: 24px;
    color: #2d405f;
  }
  #hero .btn-get-started {
    font-family: "Poppins", "kanit", sans-serif;
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 1px;
    display: block;
    padding: 8px 32px 10px 32px;
    margin-top: 25px;
    border-radius: 5px;
    transition: 0.5s;
    color: #fff;
    background: #3b4ef8;
  }
  #hero .btn-get-started:hover {
    background: #0a22f6;
  }
  #hero .hero-img {
    max-width: 60%;
    margin-top: 40px;
  }
  @media (max-width: 992px) {
    #hero h1 {
      font-size: 36px;
      line-height: 42px;
      width: 100%;
    }
    #hero h2 {
      font-size: 20px;
      line-height: 24px;
    }
    #hero .hero-img {
      max-width: 90%;
    }
  }
  @media (max-height: 768px) {
    #hero {
      height: auto;
    }
  }
  
  /*--------------------------------------------------------------
  # Sections General
  --------------------------------------------------------------*/
  section {
    padding: 60px 0;
    overflow: hidden;
  }
  
  .section-bg {
    background-color: #f6f8fb;
  }
  
  .section-title {
    text-align: center;
    padding-bottom: 30px;
  }
  .section-title h2 {
    font-size: 32px;
    font-weight: bold;
    margin-bottom: 20px;
    padding-bottom: 20px;
    position: relative;
    color: #2d405f;
  }
  .section-title h2::after {
    content: "";
    position: absolute;
    display: block;
    width: 50px;
    height: 3px;
    background: #aabbd7;
    bottom: 0;
    left: calc(50% - 25px);
  }
  .section-title p {
    margin-bottom: 0;
  }
  
  /*--------------------------------------------------------------
  # About
  --------------------------------------------------------------*/
  .about {
    background: #466393;
    background-size: cover;
    padding: 60px 0;
    position: relative;
    color: #fff;
  }
  .about .content {
    padding: 30px 30px 30px 0;
  }
  .about .content h3 {
    font-weight: 700;
    font-size: 34px;
    color: #fff;
    margin-bottom: 30px;
  }
  .about .content p {
    margin-bottom: 30px;
  }
  .about .content .about-btn {
    display: inline-block;
    background: rgba(255, 255, 255, 0.05);
    padding: 6px 30px 8px 30px;
    color: #fff;
    border-radius: 50px;
    transition: 0.3s;
  }
  .about .content .about-btn i {
    font-size: 14px;
  }
  .about .content .about-btn:hover {
    background: rgba(255, 255, 255, 0.1);
  }
  .about .icon-boxes .icon-box {
    margin-top: 30px;
  }
  .about .icon-boxes .icon-box i {
    font-size: 40px;
    color: #b6bdfc;
    margin-bottom: 10px;
  }
  .about .icon-boxes .icon-box h4 {
    font-size: 20px;
    font-weight: 700;
    margin: 0 0 10px 0;
  }
  .about .icon-boxes .icon-box p {
    font-size: 15px;
  }
  @media (max-width: 1200px) {
    .about .content {
      padding-right: 0;
    }
  }
  @media (max-width: 768px) {
    .about {
      text-align: center;
    }
  }
  
  /*--------------------------------------------------------------
  # Clients
  --------------------------------------------------------------*/
  .clients {
    background: #f6f7ff;
    padding: 15px 0;
    text-align: center;
  }
  .clients img {
    max-width: 45%;
    transition: all 0.4s ease-in-out;
    display: inline-block;
    padding: 15px 0;
  }
  .clients img:hover {
    transform: scale(1.15);
  }
  @media (max-width: 768px) {
    .clients img {
      max-width: 40%;
    }
  }
  
  /*--------------------------------------------------------------
  # Features
  --------------------------------------------------------------*/
  .features .content + .content {
    margin-top: 100px;
  }
  .features .content h3 {
    font-weight: 600;
    font-size: 26px;
  }
  .features .content ul {
    list-style: none;
    padding: 0;
  }
  .features .content ul li {
    padding-bottom: 10px;
  }
  .features .content ul i {
    font-size: 20px;
    padding-right: 4px;
    color: #3b4ef8;
  }
  .features .content p:last-child {
    margin-bottom: 0;
  }
  
  /*--------------------------------------------------------------
  # Steps
  --------------------------------------------------------------*/
  .steps {
    padding-top: 20px;
  }
  .steps .row {
    overflow: hidden;
  }
  .steps .content-item {
    padding: 40px;
    border-left: 1px solid #eee;
    border-bottom: 1px solid #eee;
    margin: -1px;
  }
  .steps .content-item span {
    display: block;
    font-size: 24px;
    font-weight: 400;
    color: #3b4ef8;
  }
  .steps .content-item h4 {
    font-size: 28px;
    font-weight: 400;
    padding: 0;
    margin: 20px 0;
    color: #2d405f;
  }
  .steps .content-item p {
    color: #aaaaaa;
    font-size: 15px;
    margin: 0;
    padding: 0;
  }
  @media (max-width: 768px) {
    .steps .content-item {
      padding: 40px 0;
    }
  }
  
  /*--------------------------------------------------------------
  # Services
  --------------------------------------------------------------*/
  .services {
    background: #2d405f;
    padding-bottom: 80px;
  }
  .services .section-title h2, .services .section-title p {
    color: #fff;
  }
  .services .section-title h2::after {
    background: rgba(255, 255, 255, 0.2);
  }
  .services .icon-box {
    padding: 50px 30px;
    position: relative;
    overflow: hidden;
    background: #fff;
    transition: all 0.3s;
    border-radius: 5px;
    text-align: center;
  }
  .services .icon-box:hover {
    transform: scale(1.08);
  }
  .services .icon {
    margin-bottom: 15px;
  }
  .services .icon i {
    font-size: 32px;
    line-height: 1;
    color: #2d405f;
    background: #eff2f8;
    padding: 20px;
    border-radius: 50px;
    transition: all 0.3s;
  }
  .services .title {
    font-weight: 700;
    margin-bottom: 15px;
    font-size: 18px;
  }
  .services .title a {
    color: #2d405f;
    transition: 0.3s;
  }
  .services .title a:hover {
    color: #4e6fa4;
  }
  .services .description {
    font-size: 15px;
    line-height: 28px;
    margin-bottom: 0;
  }
  
  /*--------------------------------------------------------------
  # Portfolio
  --------------------------------------------------------------*/
  .portfolio .portfolio-item {
    margin-bottom: 30px;
  }
  .portfolio #portfolio-flters {
    padding: 0;
    margin: 0 auto 20px auto;
    list-style: none;
    text-align: center;
  }
  .portfolio #portfolio-flters li {
    cursor: pointer;
    display: inline-block;
    padding: 8px 15px 10px 15px;
    font-size: 14px;
    font-weight: 600;
    line-height: 1;
    text-transform: uppercase;
    color: #444444;
    margin-bottom: 5px;
    transition: all 0.3s ease-in-out;
    border-radius: 3px;
  }
  .portfolio #portfolio-flters li:hover, .portfolio #portfolio-flters li.filter-active {
    color: #fff;
    background: #3b4ef8;
  }
  .portfolio #portfolio-flters li:last-child {
    margin-right: 0;
  }
  .portfolio .portfolio-wrap {
    transition: 0.3s;
    position: relative;
    overflow: hidden;
    z-index: 1;
    background: rgba(45, 64, 95, 0.6);
  }
  .portfolio .portfolio-wrap::before {
    content: "";
    background: rgba(45, 64, 95, 0.6);
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    transition: all ease-in-out 0.3s;
    z-index: 2;
    opacity: 0;
  }
  .portfolio .portfolio-wrap img {
    transition: all ease-in-out 0.3s;
  }
  .portfolio .portfolio-wrap .portfolio-info {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    transition: all ease-in-out 0.3s;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    padding: 20px;
  }
  .portfolio .portfolio-wrap .portfolio-info h4 {
    font-size: 20px;
    color: #fff;
    font-weight: 600;
  }
  .portfolio .portfolio-wrap .portfolio-info p {
    color: rgba(255, 255, 255, 0.7);
    font-size: 14px;
    text-transform: uppercase;
    padding: 0;
    margin: 0;
    font-style: italic;
  }
  .portfolio .portfolio-wrap .portfolio-links {
    text-align: center;
    z-index: 4;
  }
  .portfolio .portfolio-wrap .portfolio-links a {
    color: #fff;
    margin: 0 5px 0 0;
    font-size: 28px;
    display: inline-block;
    transition: 0.3s;
  }
  .portfolio .portfolio-wrap .portfolio-links a:hover {
    color: #9da7fc;
  }
  .portfolio .portfolio-wrap:hover::before {
    opacity: 1;
  }
  .portfolio .portfolio-wrap:hover img {
    transform: scale(1.2);
  }
  .portfolio .portfolio-wrap:hover .portfolio-info {
    opacity: 1;
  }
  
  /*--------------------------------------------------------------
  # Portfolio Details
  --------------------------------------------------------------*/
  .portfolio-details {
    padding-top: 40px;
  }
  .portfolio-details .portfolio-details-slider img {
    width: 100%;
  }
  .portfolio-details .portfolio-details-slider .swiper-pagination {
    margin-top: 20px;
    position: relative;
  }
  .portfolio-details .portfolio-details-slider .swiper-pagination .swiper-pagination-bullet {
    width: 12px;
    height: 12px;
    background-color: #fff;
    opacity: 1;
    border: 1px solid #3b4ef8;
  }
  .portfolio-details .portfolio-details-slider .swiper-pagination .swiper-pagination-bullet-active {
    background-color: #3b4ef8;
  }
  .portfolio-details .portfolio-info {
    padding: 30px;
    box-shadow: 0px 0 30px rgba(45, 64, 95, 0.08);
  }
  .portfolio-details .portfolio-info h3 {
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #eee;
  }
  .portfolio-details .portfolio-info ul {
    list-style: none;
    padding: 0;
    font-size: 15px;
  }
  .portfolio-details .portfolio-info ul li + li {
    margin-top: 10px;
  }
  .portfolio-details .portfolio-description {
    padding-top: 30px;
  }
  .portfolio-details .portfolio-description h2 {
    font-size: 26px;
    font-weight: 700;
    margin-bottom: 20px;
  }
  .portfolio-details .portfolio-description p {
    padding: 0;
  }
  
  /*--------------------------------------------------------------
  # Testimonials
  --------------------------------------------------------------*/
  .testimonials .testimonials-carousel, .testimonials .testimonials-slider {
    overflow: hidden;
  }
  .testimonials .testimonial-item {
    box-sizing: content-box;
    padding: 30px 30px 0 30px;
    margin: 30px 15px;
    text-align: center;
    min-height: 350px;
    box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.08);
    background: #fff;
  }
  .testimonials .testimonial-item .testimonial-img {
    width: 90px;
    border-radius: 50%;
    border: 4px solid #fff;
    margin: 0 auto;
  }
  .testimonials .testimonial-item h3 {
    font-size: 18px;
    font-weight: bold;
    margin: 10px 0 5px 0;
    color: #111;
  }
  .testimonials .testimonial-item h4 {
    font-size: 14px;
    color: #999;
    margin: 0;
  }
  .testimonials .testimonial-item .quote-icon-left, .testimonials .testimonial-item .quote-icon-right {
    color: white;
    font-size: 26px;
  }
  .testimonials .testimonial-item .quote-icon-left {
    display: inline-block;
    left: -5px;
    position: relative;
  }
  .testimonials .testimonial-item .quote-icon-right {
    display: inline-block;
    right: -5px;
    position: relative;
    top: 10px;
  }
  .testimonials .testimonial-item p {
    font-style: italic;
    margin: 0 auto 15px auto;
  }
  .testimonials .swiper-pagination {
    margin-top: 20px;
    position: relative;
  }
  .testimonials .swiper-pagination .swiper-pagination-bullet {
    width: 12px;
    height: 12px;
    background-color: #fff;
    opacity: 1;
    border: 1px solid #3b4ef8;
  }
  .testimonials .swiper-pagination .swiper-pagination-bullet-active {
    background-color: #3b4ef8;
  }
  
  /*--------------------------------------------------------------
  # Team
  --------------------------------------------------------------*/
  .team {
    background: #fff;
    padding: 60px 0;
  }
  .team .member {
    text-align: center;
    margin-bottom: 20px;
    background: #343a40;
    position: relative;
    overflow: hidden;
  }
  .team .member .member-info {
    opacity: 0;
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    transition: 0.2s;
  }
  .team .member .member-info-content {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 10px;
    transition: bottom 0.4s;
  }
  .team .member .member-info-content h4 {
    font-weight: 700;
    margin-bottom: 2px;
    font-size: 18px;
    color: #fff;
  }
  .team .member .member-info-content span {
    font-style: italic;
    display: block;
    font-size: 13px;
    color: #fff;
  }
  .team .member .social {
    position: absolute;
    left: 0;
    bottom: -38px;
    right: 0;
    height: 48px;
    transition: bottom ease-in-out 0.4s;
    text-align: center;
  }
  .team .member .social a {
    transition: color 0.3s;
    color: rgba(255, 255, 255, 0.7);
    margin: 0 10px;
    display: inline-block;
  }
  .team .member .social a:hover {
    color: #fff;
  }
  .team .member .social i {
    font-size: 18px;
    margin: 0 2px;
  }
  .team .member:hover .member-info {
    background: linear-gradient(0deg, rgba(12, 17, 26, 0.9) 0%, rgba(20, 29, 43, 0.8) 20%, rgba(0, 212, 255, 0) 100%);
    opacity: 1;
    transition: 0.4s;
  }
  .team .member:hover .member-info-content {
    bottom: 60px;
    transition: bottom 0.4s;
  }
  .team .member:hover .social {
    bottom: 0;
    transition: bottom ease-in-out 0.4s;
  }
  
  /*--------------------------------------------------------------
  # Pricing
  --------------------------------------------------------------*/
  .pricing .box {
    padding: 20px;
    background: #fff;
    text-align: center;
    border-radius: 8px;
    position: relative;
    overflow: hidden;
  }
  .pricing .box h3 {
    font-weight: 400;
    padding: 15px;
    font-size: 18px;
    text-transform: uppercase;
    font-weight: 600;
    color: #2d405f;
  }
  .pricing .box h4 {
    font-size: 42px;
    color: #3b4ef8;
    font-weight: 500;
    font-family: "Open Sans", sans-serif;
    margin-bottom: 20px;
  }
  .pricing .box h4 sup {
    font-size: 20px;
    top: -15px;
    left: -3px;
  }
  .pricing .box h4 span {
    color: #bababa;
    font-size: 16px;
    font-weight: 300;
  }
  .pricing .box ul {
    padding: 0;
    list-style: none;
    color: #2d405f;
    text-align: center;
    line-height: 20px;
    font-size: 14px;
  }
  .pricing .box ul li {
    padding-bottom: 16px;
  }
  .pricing .box ul i {
    color: #3b4ef8;
    font-size: 18px;
    padding-right: 4px;
  }
  .pricing .box ul .na {
    color: #ccc;
    text-decoration: line-through;
  }
  .pricing .box .btn-wrap {
    padding: 15px;
    text-align: center;
  }
  .pricing .box .btn-buy {
    background: #eceefe;
    color: #3b4ef8;
    display: inline-block;
    padding: 10px 40px 12px 40px;
    border-radius: 5px;
    transition: none;
    font-size: 14px;
    font-weight: 400;
    font-family: "Poppins", "kanit", sans-serif;
    font-weight: 600;
    transition: 0.3s;
  }
  .pricing .box .btn-buy:hover {
    background: #3b4ef8;
    color: #fff;
  }
  .pricing .featured {
    background: #466393;
    box-shadow: none;
  }
  .pricing .featured h3, .pricing .featured h4, .pricing .featured h4 span, .pricing .featured ul, .pricing .featured ul .na {
    color: #fff;
  }
  .pricing .featured .btn-buy {
    background: rgba(255, 255, 255, 0.1);
    color: #fff;
  }
  .pricing .featured .btn-buy:hover {
    background: #fff;
    color: #4e6fa4;
  }
  
  /*--------------------------------------------------------------
  # Frequently Asked Questions
  --------------------------------------------------------------*/
  .faq {
    padding: 60px 0;
  }
  .faq .faq-list {
    padding: 0;
    list-style: none;
  }
  .faq .faq-list li {
    border-bottom: 1px solid #eceefe;
    margin-bottom: 20px;
    padding-bottom: 20px;
  }
  .faq .faq-list .question {
    display: block;
    position: relative;
    font-family: #3b4ef8;
    font-size: 18px;
    line-height: 24px;
    font-weight: 400;
    padding-left: 25px;
    cursor: pointer;
    color: #0a22f6;
    transition: 0.3s;
  }
  .faq .faq-list i {
    font-size: 16px;
    position: absolute;
    left: 0;
    top: -2px;
  }
  .faq .faq-list p {
    margin-bottom: 0;
    padding: 10px 0 0 25px;
  }
  .faq .faq-list .icon-show {
    display: none;
  }
  .faq .faq-list .collapsed {
    color: black;
  }
  .faq .faq-list .collapsed:hover {
    color: #3b4ef8;
  }
  .faq .faq-list .collapsed .icon-show {
    display: inline-block;
    transition: 0.6s;
  }
  .faq .faq-list .collapsed .icon-close {
    display: none;
    transition: 0.6s;
  }
  
  /*--------------------------------------------------------------
  # Contact
  --------------------------------------------------------------*/
  .contact .info-box {
    color: #2d405f;
    text-align: center;
    box-shadow: 0 0 30px rgba(214, 215, 216, 0.6);
    padding: 20px 0 30px 0;
    background: #fff;
  }
  .contact .info-box i {
    font-size: 32px;
    color: #3b4ef8;
    padding: 8px;
  }
  .contact .info-box h3 {
    font-size: 20px;
    color: #777777;
    font-weight: 700;
    margin: 10px 0;
  }
  .contact .info-box p {
    padding: 0;
    line-height: 24px;
    font-size: 14px;
    margin-bottom: 0;
  }
  .contact .php-email-form {
    box-shadow: 0 0 30px rgba(214, 215, 216, 0.6);
    padding: 30px;
    background: #fff;
  }
  .contact .php-email-form .error-message {
    display: none;
    color: #fff;
    background: #ed3c0d;
    text-align: left;
    padding: 15px;
    font-weight: 600;
  }
  .contact .php-email-form .error-message br + br {
    margin-top: 25px;
  }
  .contact .php-email-form .sent-message {
    display: none;
    color: #fff;
    background: #18d26e;
    text-align: center;
    padding: 15px;
    font-weight: 600;
  }
  .contact .php-email-form .loading {
    display: none;
    background: #fff;
    text-align: center;
    padding: 15px;
  }
  .contact .php-email-form .loading:before {
    content: "";
    display: inline-block;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    margin: 0 10px -6px 0;
    border: 3px solid #18d26e;
    border-top-color: #eee;
    -webkit-animation: animate-loading 1s linear infinite;
    animation: animate-loading 1s linear infinite;
  }
  .contact .php-email-form input, .contact .php-email-form textarea {
    border-radius: 5px;
    box-shadow: none;
    font-size: 14px;
  }
  .contact .php-email-form input:focus, .contact .php-email-form textarea:focus {
    border-color: #8ea5ca;
  }
  .contact .php-email-form input {
    padding: 10px 15px;
  }
  .contact .php-email-form textarea {
    padding: 12px 15px;
  }
  .contact .php-email-form button[type=submit] {
    background: #3b4ef8;
    border: 0;
    padding: 10px 24px;
    color: #fff;
    transition: 0.4s;
    border-radius: 5px;
  }
  .contact .php-email-form button[type=submit]:hover {
    background: #0a22f6;
  }
  @-webkit-keyframes animate-loading {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  @keyframes animate-loading {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  /*--------------------------------------------------------------
  # Breadcrumbs
  --------------------------------------------------------------*/
  .breadcrumbs {
    margin-top: 70px;
    padding: 10px 0;
    box-shadow: 0px 2px 15px rgba(45, 64, 95, 0.06);
  }
  .breadcrumbs ol {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    padding: 0;
    margin: 15px 0;
  }
  .breadcrumbs ol li + li {
    padding-left: 10px;
  }
  .breadcrumbs ol li + li::before {
    display: inline-block;
    padding-right: 10px;
    color: #3d5782;
    content: "/";
  }
  
  /*--------------------------------------------------------------
  # Footer
  --------------------------------------------------------------*/
  #footer {
    color: #444444;
    font-size: 12px;
    background: #f1f3ff;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
  }
  #footer .footer-top {
    padding: 60px 0 30px 0;
    background: #fff;
  }
  #footer .footer-top .footer-contact {
    margin-bottom: 30px;
  }
  #footer .footer-top .footer-contact h4 {
    font-size: 22px;
    margin: 0 0 30px 0;
    padding: 2px 0 2px 0;
    line-height: 1;
    font-weight: 700;
    color: #2d405f;
  }
  #footer .footer-top .footer-contact p {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 0;
    font-family: "Poppins", "kanit", sans-serif;
    color: #4e6fa4;
  }
  #footer .footer-top h4 {
    font-size: 16px;
    font-weight: bold;
    color: #2d405f;
    position: relative;
    padding-bottom: 12px;
  }
  #footer .footer-top .footer-links {
    margin-bottom: 30px;
  }
  #footer .footer-top .footer-links ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  #footer .footer-top .footer-links ul i {
    padding-right: 2px;
    color: #6c7afa;
    font-size: 18px;
    line-height: 1;
  }
  #footer .footer-top .footer-links ul li {
    padding: 10px 0;
    display: flex;
    align-items: center;
  }
  #footer .footer-top .footer-links ul li:first-child {
    padding-top: 0;
  }
  #footer .footer-top .footer-links ul a {
    color: #777777;
    transition: 0.3s;
    display: inline-block;
    line-height: 1;
  }
  #footer .footer-top .footer-links ul a:hover {
    text-decoration: none;
    color: #3b4ef8;
  }
  #footer .footer-newsletter {
    font-size: 15px;
  }
  #footer .footer-newsletter h4 {
    font-size: 16px;
    font-weight: bold;
    color: #2d405f;
    position: relative;
    padding-bottom: 12px;
  }
  #footer .footer-newsletter form {
    margin-top: 30px;
    background: #fff;
    padding: 6px 10px;
    position: relative;
    border-radius: 5px;
    text-align: left;
    border: 1px solid #9da7fc;
  }
  #footer .footer-newsletter form input[type=email] {
    border: 0;
    padding: 4px 8px;
    width: calc(100% - 100px);
  }
  #footer .footer-newsletter form input[type=submit] {
    position: absolute;
    top: -1px;
    right: -2px;
    bottom: -1px;
    border: 0;
    background: none;
    font-size: 16px;
    padding: 0 20px;
    background: #3b4ef8;
    color: #fff;
    transition: 0.3s;
    border-radius: 0 5px 5px 0;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
  }
  #footer .footer-newsletter form input[type=submit]:hover {
    background: #0a22f6;
  }
  #footer .credits {
    padding-top: 0;
    font-size: 12px;
    color: #444444;
  }
  #footer .social-links a {
    font-size: 18px;
    display: inline-block;
    background: #e7eafe;
    color: #3b4ef8;
    line-height: 1;
    padding: 8px 0;
    margin-right: 4px;
    border-radius: 5px;
    text-align: center;
    width: 36px;
    height: 36px;
    transition: 0.3s;
  }
  #footer .social-links a:hover {
    background: #3b4ef8;
    color: #fff;
    text-decoration: none;
  }
